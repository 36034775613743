import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Eco from '@travauxlib/shared/src/components/DesignSystem/assets/Eco.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import Exclamation from '@travauxlib/shared/src/components/DesignSystem/assets/Exclamation.svg?react';
import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/Eye.svg?react';
import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import StateChecked from '@travauxlib/shared/src/components/DesignSystem/assets/StateChecked.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { Divider } from '@travauxlib/shared/src/components/DesignSystem/components/Divider';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { InteractiveTags } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/InteractiveTags';
import { TagOption } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/types';
import { Toggle } from '@travauxlib/shared/src/components/DesignSystem/components/Toggle';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { Gamme } from '@travauxlib/shared/src/types';
import { TypeTravaux } from '@travauxlib/shared/src/types';

import { Answers, BaseDePrix, Configuration, HubClosed, QuestionId } from '../../types';
import { formData } from '../../utils/formData';
import { getToggleCardItems } from '../../utils/getToggleCardItems';

type Props = {
  tooltip?: string;
  onClickPreview: (questionId?: QuestionId) => void;
  questionId: QuestionId;
  readOnly: boolean;
  configuration: Configuration;
  baseDePrix: BaseDePrix;
  hubClosed?: HubClosed;
  setHubClosed?: (hubId: keyof Answers, close: boolean) => void;
  onChangeGamme?: (gamme: Gamme, questionId: QuestionId) => void;
  isRge?: boolean;
};

const MinBottomSectionSize = 100;
const TagSize = 55;

const optionsGammes: TagOption[] = [
  { label: 'Primo', variant: 'light', value: Gamme.primo },
  { label: 'Confort', variant: 'info', value: Gamme.confort },
  { label: 'Premium', variant: 'success', value: Gamme.premium },
  { label: 'Exclusif', variant: 'beige', value: Gamme.exclusif },
];

export const ToggleCard: React.FC<Props> = ({
  tooltip,
  onClickPreview,
  onChangeGamme,
  questionId,
  readOnly,
  configuration,
  baseDePrix,
  hubClosed,
  setHubClosed,
  isRge,
}) => {
  const defaultOpen: boolean =
    hubClosed?.[questionId] === undefined
      ? configuration.answers?.[questionId] !== undefined ||
        configuration.typeTravaux !== TypeTravaux.RenovationPartielle
      : !hubClosed?.[questionId];

  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const items = getToggleCardItems(baseDePrix, questionId, configuration);

  const { title, editLink, Icon } = formData[questionId];

  if (readOnly && !items.length) {
    return null;
  }

  return (
    <Card
      className="overflow-visible"
      bodyClassNames="!py-0 !px-md"
      state="outlined"
      data-testid={questionId}
      role="group"
      aria-labelledby={questionId}
    >
      <div className="flex items-center py-xs">
        <div className="flex grow items-center mr-md ">
          <ColoredCircle size="lg" className="mr-xs relative" variant="beige">
            <Icon className="w-[2rem] h-[2rem]" />
            {isRge && (
              <WithTooltip
                trigger={
                  <span className="absolute -bottom-3xs -right-3xs">
                    <Eco className="w-[1.25rem] h-[1.25rem] text-success-600" />
                  </span>
                }
                width="12.875rem"
                position="top-start"
                fixedFloating
              >
                <div className="text-b2 font-normal p-0 m-0">
                  Contient des prestations de rénovation énergétique
                </div>
              </WithTooltip>
            )}
          </ColoredCircle>
          <div className="flex items-center">
            <div>
              <div
                className="text-b1 font-bold mr-xs mb-xxs max-w-[12.5rem] sm-desktop:max-w-[14rem] truncate text-neutral-800"
                id={questionId}
              >
                {title}
              </div>
              <InteractiveTags
                value={configuration.answers?.[questionId]?.gamme || configuration.mainGamme}
                onChange={(newGamme: Gamme) => onChangeGamme && onChangeGamme(newGamme, questionId)}
                options={optionsGammes}
                disabled={readOnly}
              />
            </div>
            {tooltip && (
              <WithTooltip
                trigger={
                  <span>
                    <HelpCircleOutline className="text-neutral-600 w-lg h-lg" />
                  </span>
                }
              >
                <div>{tooltip}</div>
              </WithTooltip>
            )}
          </div>
        </div>
        {!readOnly && (
          <Toggle
            checked={isOpen}
            onChange={value => {
              setIsOpen(value);
              if (setHubClosed) {
                setHubClosed(questionId, !value);
              }
            }}
          />
        )}
      </div>
      <div
        className={classNames('transition-all duration-[300ms] overflow-hidden max-h-0')}
        //eslint-disable-next-line
        style={
          isOpen
            ? {
                maxHeight: Math.max(MinBottomSectionSize, items.length * TagSize),
              }
            : {}
        }
      >
        <Divider className="mx-auto" />

        {items.length !== 0 && (
          <div className="py-xs flex">
            <div className="mr-xs grow">
              {items.map((item, index) => (
                <div
                  data-testid="summary-item"
                  key={`${item.label}-${index}`}
                  className="text-b2 flex items-start mb-xxs"
                >
                  <div className="mr-xxs my-[2px] flex">
                    {item.inProgress || item.toCheck ? (
                      <Exclamation className="w-md h-md text-warning-600" data-testid="progress" />
                    ) : (
                      <StateChecked className="w-md h-md text-success-600" data-testid="done" />
                    )}
                  </div>
                  <div>
                    <span>{`${item.label}${item.quantity !== 0 ? ' : ' : ''}`}</span>
                    {item.quantity !== 0 && (
                      <span className="font-medium">
                        {item.quantity.toLocaleString('fr-fr')}
                        {item.unit}
                      </span>
                    )}
                    {(item.inProgress || item.toCheck) && (
                      <span className=" font-medium">
                        {' '}
                        :{' '}
                        <span className="text-warning-600">
                          {item.inProgress ? 'À compléter' : 'À vérifier'}
                        </span>
                      </span>
                    )}
                    {item.isOption && <span className="text-primary text-sm"> (Option)</span>}
                  </div>
                </div>
              ))}
            </div>
            <div>
              {!readOnly && (
                <>
                  <Link to={editLink}>
                    <IconButton data-testid="edit" className="mb-xxs">
                      <Edit className="text-neutral-700" />
                    </IconButton>
                  </Link>
                  <IconButton data-testid="preview" onClick={() => onClickPreview(questionId)}>
                    <Eye className="text-neutral-700" />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        )}
        {items.length === 0 && !readOnly && (
          <div className="flex justify-center pt-sm mb-md">
            <ButtonLink fullwidth variant="secondary" to={editLink}>
              Ajouter les prestations
            </ButtonLink>
          </div>
        )}
        {items.length === 0 && readOnly && (
          <div className="py-xs flex">
            <div className="mr-xs grow">Pas de prestations</div>
          </div>
        )}
      </div>
    </Card>
  );
};
