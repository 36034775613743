import React from 'react';

import dayjs from 'dayjs';
import { Form } from 'react-final-form';

import { CopyToClipboardButton } from '@travauxlib/shared/src/components/CopyToClipboardButton';
import Key from '@travauxlib/shared/src/components/DesignSystem/assets/Key.svg?react';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import { required, validateGoogleMapAddress } from '@travauxlib/shared/src/utils/form';

import { PeopleSelectorField } from 'components/PeopleSelector/Field';
import { useAttribuableToPartners } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { PartnerAgency } from 'types';
import { appUrls } from 'utils/urls';

import { AttributionNameField } from './AttributionNameField';
import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

type Props = {
  onSubmit: (values: PartnerAgency) => Promise<unknown>;
  initialValues?: Partial<PartnerAgency>;
};

export const AgencyForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const isAllowedToModifyAccountManager = useIsAllowed('Partner', 'manage');
  const { persons: partnersTeamMembers, isLoading } = useAttribuableToPartners();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form<PartnerAgency> onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, dirty, submitting }) => (
        <PartnerDetailsContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Enregistrer',
            type: 'submit',
            disabled: !dirty || submitting,
            loading: submitting,
          }}
        >
          <>
            {initialValues?.token ? (
              <>
                <div className="flex justify-between items-center mb-md">
                  <div>
                    <Link href={appUrls.homePartenaire(initialValues.token)} icon={<Key />}>
                      Token : {initialValues.token}
                    </Link>
                    <CopyToClipboardButton
                      valueToCopy={appUrls.homePartenaire(initialValues.token)}
                    />
                  </div>
                  Créée le {dayjs(initialValues.createdAt).format('DD/MM/YYYY')}
                </div>
                <h3 className="mb-md">Agence {initialValues.name}</h3>
              </>
            ) : (
              <h3 className="mb-md">Nouvelle agence</h3>
            )}
            <div className="grid grid-cols-12 gap-md">
              <div className="col-span-full sm-desktop:col-span-6 pb-md">
                <InputField id="agencyName" name="name" label="Agence" validate={required} />
              </div>
              <div className="col-span-full sm-desktop:col-span-6">
                <PeopleSelectorField
                  id="internalAccountManager"
                  name="internalAccountManager"
                  label="Account manager"
                  persons={partnersTeamMembers}
                  disabled={!isAllowedToModifyAccountManager}
                  allowEmpty
                />
              </div>
              <div className="col-span-full sm-desktop:col-span-6">
                <InputField id="billingEmail" name="billingEmail" label="Email facturation" />
              </div>
              <div className="col-span-full sm-desktop:col-span-6">
                <PlacesAutocompleteField
                  id="googleMapsAddress"
                  name="googleMapsAddress"
                  label="Adresse"
                  validate={validateGoogleMapAddress('postal_code')}
                  googleApiKey={APP_CONFIG.googleApiKey}
                  initialInputValue={initialValues?.googleMapsAddress?.formatted_address}
                />
              </div>
              <div className="col-span-full">
                <CheckboxField
                  id="isArchived"
                  name="isArchived"
                  label="Désactiver l'agence"
                  className="!block !mb-md"
                  defaultValue={false}
                />
              </div>
              <div className="col-start-2 col-end-12">
                <hr className="my-0" />
              </div>
              <div className="col-span-full text-h5">Attribution</div>
              <div className="col-span-full sm-desktop:col-span-6">
                <DropdownField
                  id="source"
                  name="source"
                  label="Source"
                  validate={required}
                  options={[
                    { value: 'partner_offline', label: 'partner_offline' },
                    { value: 'partner_online', label: 'partner_online' },
                    { value: 'telephone', label: 'telephone' }, // useful for call center only
                  ]}
                />
              </div>
              <div className="col-span-full sm-desktop:col-span-6">
                <AttributionNameField />
              </div>
              <div className="col-start-2 col-end-12">
                <hr className="my-0" />
              </div>
              <div className="col-span-full text-h5">Estimateur</div>
              <div className="col-span-full sm-desktop:col-span-6">
                <CheckboxField
                  id="estimateurConfigDisabled"
                  name="estimateurConfig.disabled"
                  label="Bloquer l'accès à l'estimateur"
                  className="!block !mb-md"
                  defaultValue={false}
                />
                <CheckboxField
                  id="estimateurConfigShowLogoInPDF"
                  name="estimateurConfig.showLogoInPDF"
                  label="Afficher le logo dans les PDF"
                  className="!block !mb-md"
                  defaultValue={false}
                />
                <CheckboxField
                  id="disableRegistrationEmail"
                  name="disableRegistrationEmail"
                  label="Ne pas envoyer les emails automatiques à la création des comptes"
                  className="!block !mb-md"
                  defaultValue={false}
                />
              </div>
            </div>
          </>
        </PartnerDetailsContent>
      )}
    </Form>
  );
};
