import React, { useMemo } from 'react';

import _groupBy from 'lodash/groupBy';
import _partition from 'lodash/partition';
import { useSearchParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { FactureStatuses } from '@travauxlib/shared/src/types';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { useArchiveCdplInvoice } from 'features/CdplFacturation/api/useArchiveCdplInvoice';
import { useCdplInvoices } from 'features/CdplFacturation/api/useCdplInvoices';
import { useUpdateStatusCdplInvoice } from 'features/CdplFacturation/api/useUpdateStatusCdplInvoice';
import { CDPLFactureTable } from 'features/CdplFacturation/components/CDPLFactureTable';

export const GestionFacturationCdpl: React.FC = () => {
  const { invoices, isLoading } = useCdplInvoices();
  const archiveInvoice = useArchiveCdplInvoice();
  const [searchParams] = useSearchParams();
  const currentStatus = searchParams.get('status') || 'encours';
  const { updateStatus, isPending } = useUpdateStatusCdplInvoice();

  const { facturesDraft, factureAwaitingPayment, facturesReglees, facturesArchivees } =
    useMemo(() => {
      const [facturesArchivees, facturesActives] = _partition(invoices, 'archived');

      const facturesParStatus = _groupBy(facturesActives, 'status');

      return {
        facturesArchivees,
        facturesDraft: facturesParStatus[FactureStatuses.draft] || [],
        factureAwaitingPayment: facturesParStatus[FactureStatuses.awaiting_payment] || [],
        facturesReglees: facturesParStatus[FactureStatuses.paid] || [],
      };
    }, [invoices]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={PageTitleEnum.gestionFacturation} />
      <div className="text-h3 font-bold">Facturation CDPL</div>
      <Tabs
        items={[
          {
            label: 'Factures en cours',
            to: { search: 'status=encours' },
            isActive: () => currentStatus === 'encours',
          },
          {
            label: 'Factures réglées',
            to: { search: 'status=reglees' },
            isActive: () => currentStatus === 'reglees',
          },
          {
            label: 'Factures archivées',
            to: { search: 'status=archivees' },
            isActive: () => currentStatus === 'archivees',
          },
        ]}
      />
      {currentStatus === 'reglees' && (
        <CDPLFactureTable
          factures={facturesReglees}
          archiveAction={archiveInvoice}
          status={FactureStatuses.paid}
        />
      )}
      {currentStatus === 'archivees' && <CDPLFactureTable factures={facturesArchivees} />}
      {currentStatus === 'encours' && (
        <>
          <div className="text-h4 font-bold my-sm">Factures à envoyer à la comptabilité</div>
          <CDPLFactureTable
            factures={facturesDraft}
            archiveAction={archiveInvoice}
            status={FactureStatuses.draft}
            submitting={isPending}
            action={{
              label: 'Envoyer',
              onClick: tokens =>
                updateStatus({
                  tokens,
                  status: FactureStatuses.awaiting_payment,
                }),
            }}
          />
          <div className="text-h4 font-bold my-sm">Factures à marquer comme réglées</div>
          <CDPLFactureTable
            factures={factureAwaitingPayment}
            archiveAction={archiveInvoice}
            status={FactureStatuses.awaiting_payment}
            submitting={isPending}
            action={{
              label: 'Marquer comme réglées',
              onClick: tokens =>
                updateStatus({
                  tokens,
                  status: FactureStatuses.paid,
                }),
            }}
          />
        </>
      )}
    </>
  );
};
