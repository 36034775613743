import React from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { DevisContractor, Lot as LotType } from '@travauxlib/shared/src/types';

import { useDevisDisplayContext } from './DevisDisplayContext';
import { Item } from './Item';

type Props = {
  lotIndex: number;
  lot: LotType;
  showOnlyFacturable: boolean;
  contractors?: DevisContractor[];
  isSSR?: boolean;
};

export const LotComponent: React.FC<Props> = ({
  lot,
  lotIndex,
  showOnlyFacturable,
  contractors,
}) => {
  const { customization, hidePrices } = useDevisDisplayContext();

  const filteredItems = lot.items.filter(item => {
    if (showOnlyFacturable && item.type === 'ligne') {
      return item.status !== 'option';
    }

    return true;
  });

  const lotContractors = contractors?.filter(c => c.lots.includes(lot.uuid));

  if (filteredItems.length === 0) {
    return null;
  }

  return (
    <div id={lot.uuid} className="pt-xs pb-xl">
      <div className="flex break-inside-avoid relative mb-lg px-xs">
        <div className="w-xl flex-grow-0 flex-shrink-0 text-gray-600 mr-xs">{lotIndex + 1}.0</div>
        <div className="flex justify-between flex-grow">
          <div
            className={classNames(
              'flex text-neutral-800 text-b1',
              customization?.subTitleFontStyle || 'font-bold',
            )}
            //eslint-disable-next-line
            style={{ color: customization?.textColor }}
            data-testid="lot-label"
          >
            {lot.label}
          </div>
          <div className="font-bold text-b1 text-neutral-800">
            {!hidePrices && (
              <>
                <EURCurrency amount={lot.prixTotalHT} />
                &nbsp;HT
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {lotContractors && lotContractors.length > 0 && (
          <MultilineParagraph
            paragraphClassName="text-neutral-600"
            text={`Sous traitant RGE : ${lotContractors
              .map(contractor => contractor.name)
              .join(', ')}`}
          />
        )}
        {filteredItems.map(item => (
          <div
            key={item.uuid}
            className={classNames(item.type === 'pagebreak' && 'break-before-page')}
          >
            <Item
              lotFacturationPercentage={lot.facturationPercentage}
              lotIndex={lotIndex}
              itemIndex={lot.items
                .filter(l => l.type === 'ligne')
                .findIndex(l => l.uuid === item.uuid)}
              item={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
