import React from 'react';

import classNames from 'classnames';

import { config } from './constants';
import { Icon } from './Icon';
import { Level } from './types';

type Props = {
  children: React.ReactNode;
  level: Level;
  className?: string;
};

export const InlineAlert: React.FC<Props> = ({ children, level, className }) => {
  const { textClassNames } = config[level];

  return (
    <div className={classNames('flex items-center gap-xxs grow', className)}>
      <Icon level={level} />
      <div className={classNames('w-full flex text-sm', textClassNames)}>{children}</div>
    </div>
  );
};
