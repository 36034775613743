import React, { useEffect, useState } from 'react';

import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  Placement,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import classNames from 'classnames';

type Props = {
  position?: Placement;
  trigger: (toggle: () => void, referenceProps: Object, isOpen: boolean) => React.ReactElement;
  fixedFloating?: boolean;
  offsetY?: number;
  offsetX?: number;
  height?: number;
  scrollable?: boolean;
  children: React.ReactElement | ((closePopup: () => void) => React.ReactElement);
  defaultOpen?: boolean;
  className?: string;
};

export const Popup: React.FC<Props> = ({
  defaultOpen,
  position = 'bottom-end',
  fixedFloating,
  trigger,
  offsetY = 0,
  offsetX,
  height = 200,
  scrollable,
  children,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);
  useEffect(() => {
    const handler = (): void => setIsOpen(false);

    window.addEventListener('scroll', handler);

    return () => window.removeEventListener('scroll', handler);
  }, []);
  const { refs, floatingStyles, context } = useFloating({
    placement: position,
    strategy: fixedFloating ? 'fixed' : 'absolute',
    open: isOpen,
    middleware: [
      offset({
        mainAxis: offsetX || 0,
        crossAxis: offsetY || 0,
      }),
    ],
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  });

  const close = (): void => setIsOpen(false);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'click',
  });
  const role = useRole(context);
  const toggleOpen = (): void => {
    setIsOpen(isOpen => !isOpen);
  };
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role]);

  const referenceProps = {
    ref: refs.setReference,
    ...getReferenceProps(),
  };
  return (
    <>
      {trigger(toggleOpen, referenceProps, isOpen)}
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            //eslint-disable-next-line
            style={{
              ...floatingStyles,
              width: 'initial',
              padding: '4px 0',
            }}
            className={classNames('absolute z-50 bg-white border shadow-xs rounded-xxs', className)}
          >
            <div
              //eslint-disable-next-line
              style={scrollable ? { maxHeight: `${height}px` } : {}}
              className={classNames('ignore-click-outside', {
                [`overflow-y-scroll`]: scrollable,
              })}
            >
              {typeof children === 'function' ? children(close) : children}
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
