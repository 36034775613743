import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { Person, PersonRole } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

const PERSONS_KEY = 'persons';

const handleUpdatePerson = async (payload: Person): Promise<void> => {
  const { uuid } = payload;
  const requestURL = `${APP_CONFIG.apiURL}/admin/employees/persons/${uuid}`;
  await request(requestURL, {
    method: 'PUT',
    body: payload,
  });
};

const handleCreatePerson = async (payload: Person): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/employees/persons`;
  await request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export type UploadPicturePayload = {
  personUuid: string;
  isOfficial: boolean;
  data: {
    type: string;
  };
};

const handleUploadPicture = async ({
  personUuid,
  isOfficial = true,
  data,
}: UploadPicturePayload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/employees/persons/${personUuid}/picture/${
    isOfficial ? 'official' : 'bonus'
  }`;

  await request(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': data.type,
    },
    body: data,
  });
};

export const usePersons = (
  roleFilters?: PersonRole[],
): {
  persons: Person[];
  isLoading: boolean;
  createOrUpdatePerson: typeof handleCreatePerson;
  uploadPicture: typeof handleUploadPicture;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: persons = [], isLoading } = useQuery<Person[]>({
    queryKey: [PERSONS_KEY],
    queryFn: async () => {
      const result = await request(`${APP_CONFIG.apiURL}/admin/employees/persons`);
      return result;
    },
  });

  const { mutateAsync: updatePerson } = useMutation({
    mutationFn: handleUpdatePerson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PERSONS_KEY] });
      toast.success('Mise à jour effectuée.');
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la mise à jour');
    },
  });

  const { mutateAsync: createPerson } = useMutation({
    mutationFn: handleCreatePerson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PERSONS_KEY] });
      navigate('/trombinoscope/edit');
    },
    onError: () => {
      toast.error('Un utilisateur avec cet email existe déjà');
    },
  });

  const { mutateAsync: uploadPicture } = useMutation({
    mutationFn: handleUploadPicture,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PERSONS_KEY] });
    },
  });

  const createOrUpdatePerson = (payload: Person): Promise<void> =>
    payload.uuid ? updatePerson(payload) : createPerson(payload);

  const filteredPersons = roleFilters
    ? persons.filter(({ role }) => role && roleFilters.includes(role))
    : persons;

  return {
    persons: filteredPersons,
    uploadPicture,
    createOrUpdatePerson,
    isLoading,
  };
};

export const usePerson = (uuid?: string): { person?: Person; isLoading: boolean } => {
  const { data, isLoading, fetchStatus } = useQuery<Person>({
    queryKey: [PERSONS_KEY, uuid],
    queryFn: () => request(`${APP_CONFIG.apiURL}/admin/employees/persons/${uuid}`),
    enabled: !!uuid,
  });

  return { person: data, isLoading: isLoading && fetchStatus !== 'idle' };
};

export const useAttribuableEmployees = (): {
  persons: Person[];
  isLoading: boolean;
  createOrUpdatePerson: (payload: Person) => Promise<void>;
} => {
  const data = usePersons([
    PersonRole.CDP,
    PersonRole.CDPL,
    PersonRole.TeamLeadCDP,
    PersonRole.TeamLeadPartenariat,
    PersonRole.ResponsablePartenariat,
  ]);

  return {
    persons: data.persons,
    isLoading: data.isLoading,
    createOrUpdatePerson: data.createOrUpdatePerson,
  };
};

export const useAttribuableToPartners = (): {
  persons: Person[];
  isLoading: boolean;
  createOrUpdatePerson: (payload: Person) => Promise<void>;
} => {
  const data = usePersons([
    PersonRole.CDP,
    PersonRole.CDPL,
    PersonRole.TeamLeadCDP,
    PersonRole.TeamLeadPartenariat,
    PersonRole.ResponsablePartenariat,
  ]);

  return {
    persons: data.persons,
    isLoading: data.isLoading,
    createOrUpdatePerson: data.createOrUpdatePerson,
  };
};
