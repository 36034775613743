import React from 'react';

import classNames from 'classnames';

import { InputErrorText } from '@travauxlib/shared/src/components/DesignSystem/components/Input/commons';

import CheckSymbol from '../../assets/CheckSymbol.svg?react';
import MinusSymbol from '../../assets/MinusSymbol.svg?react';
import {
  commonFormControlCheckmarkClasses,
  commonFormControlContainerClasses,
  FormControlProps,
  GenericFormControlContainer,
} from '../FormControl/commons';

export type Props = FormControlProps<boolean> & { error?: string; checked: boolean };

export const Checkbox: React.FC<Props> = ({
  className,
  label,
  checked,
  disabled,
  onChange,
  error,
  indeterminate,
  ...rest
}) => (
  <>
    <GenericFormControlContainer
      height={18}
      width={18}
      onChange={e => onChange?.(e.target.checked)}
      type="checkbox"
      label={label}
      className={className}
      checked={checked}
      disabled={disabled}
      {...rest}
    >
      <span
        className={classNames(
          'rounded-xxs inline-flex shrink-0',
          commonFormControlContainerClasses(checked, disabled, !!error),
          commonFormControlCheckmarkClasses(checked, disabled, indeterminate),
        )}
        aria-hidden
      >
        {indeterminate ? (
          <MinusSymbol className="size-xmd" fill="white" />
        ) : checked ? (
          <CheckSymbol className="size-xmd" fill="white" />
        ) : (
          <div className="size-xmd" />
        )}
      </span>
    </GenericFormControlContainer>
    <InputErrorText error={error} />
  </>
);
