import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { CDPLFacture } from '../types';

export const CDPL_INVOICES_KEY = 'CDPL_INVOICES';

type APIResponse = {
  data: CDPLFacture[];
};

const fetchInvoices = (): Promise<APIResponse> =>
  request(`${APP_CONFIG.apiURL}/admin/cdpl/invoices`);

export const useCdplInvoices = (): {
  isLoading: boolean;
  invoices: CDPLFacture[];
} => {
  const { isLoading, data } = useQuery<APIResponse>({
    queryKey: [CDPL_INVOICES_KEY],
    queryFn: fetchInvoices,
  });

  return { isLoading, invoices: data?.data || [] };
};
