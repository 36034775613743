import React, { useState } from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useCommissions } from '../../api/useCommissions';
import { CommissionTable } from '../CommissionTable';

type Props = {
  displayPaid?: boolean;
};

export const Commissions: React.FC<Props> = ({ displayPaid }) => {
  const [showArchived, setShowArchived] = useState(false);
  const {
    commissionsToSend,
    commissionsToBill,
    commissionsToPay,
    commissionsPaid,
    commissionsPending,
    isLoading,
  } = useCommissions({ showArchived });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="mb-md pt-lg">
        <Checkbox
          label="Afficher les commissions archivées"
          checked={showArchived}
          onChange={() => setShowArchived(showArchived => !showArchived)}
        />
      </div>
      {displayPaid ? (
        <CommissionTable title="Réglées" commissions={commissionsPaid} />
      ) : (
        <>
          <CommissionTable title="En attente de commission" commissions={commissionsPending} />
          <CommissionTable
            title="Demandes à envoyer"
            commissions={commissionsToSend}
            ctaLabel="Envoyer"
          />
          <CommissionTable
            title="En attente de factures"
            commissions={commissionsToBill}
            ctaLabel="Envoyer"
          />
          <CommissionTable
            title="En attente de paiement"
            commissions={commissionsToPay}
            ctaLabel="Marquer comme réglées"
          />
        </>
      )}
    </div>
  );
};
