import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { CDPL_INVOICES_KEY } from 'features/CdplFacturation/api/useCdplInvoices';

export const useArchiveCdplInvoice = (): UseMutateAsyncFunction<void, Error, String> => {
  const queryClient = useQueryClient();

  const { mutateAsync: archiveFacture } = useMutation({
    mutationFn: (token: String) =>
      request(`${APP_CONFIG.apiURL}/admin/cdpl/invoices/${token}`, {
        method: 'DELETE',
      }),
    onSuccess: () => {
      toast.success('La facture a bien été supprimée');
      queryClient.invalidateQueries({ queryKey: [CDPL_INVOICES_KEY] });
    },
    onError: () => {
      toast.error('Impossible de supprimer la facture');
    },
  });

  return archiveFacture;
};
