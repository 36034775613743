import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

export const GestionFacturation: React.FC = () => (
  <div className="flex-grow h-full flex items-center justify-center gap-md font-bold text-h3 text-center flex-wrap">
    <Card
      className="w-[25rem] max-w-full h-[10rem] flex items-center justify-center"
      to="apporteur/commissions-actives"
    >
      Facturation Apporteur d'affaires
    </Card>
    <Card to="cdpl" className="w-[25rem] h-[10rem] max-w-full flex items-center justify-center">
      Facturation CDPL
    </Card>
  </div>
);
