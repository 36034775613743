import Booster from '@travauxlib/shared/src/components/DesignSystem/assets/Booster.svg?react';
import CalculateOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CalculateOutline.svg?react';
import Dashboard from '@travauxlib/shared/src/components/DesignSystem/assets/Dashboard.svg?react';
import DocumentEdit from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentEdit.svg?react';
import HandshakeOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandshakeOutline.svg?react';
import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import Invoice from '@travauxlib/shared/src/components/DesignSystem/assets/Invoice.svg?react';
import LittleStarsOutline from '@travauxlib/shared/src/components/DesignSystem/assets/LittleStarsOutline.svg?react';
import ProjectIcon from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import StoreOutline from '@travauxlib/shared/src/components/DesignSystem/assets/StoreOutline.svg?react';

import { adminUrls } from 'utils/urls';

type Permissions = {
  canManageFacturation: boolean;
  isCDPL: boolean;
};

export const navItems = [
  {
    Icon: Dashboard,
    to: adminUrls.leadsUrl,
    label: 'Dashboard CDP',
  },
  {
    Icon: DocumentEdit,
    to: adminUrls.proRegistrations,
    label: 'Inscriptions pro',
  },
  {
    Icon: ProjectIcon,
    to: '/projects',
    label: 'Gestion de projet',
  },
  {
    Icon: StoreOutline,
    to: '/entreprises',
    label: 'Entreprises',
  },
  {
    Icon: Booster,
    to: '/leads',
    label: 'Leads',
  },
  {
    Icon: HandyManOutline,
    to: '/chantiers',
    label: 'Chantiers',
  },
  {
    Icon: HandshakeOutline,
    to: adminUrls.partnerUrl,
    label: 'Partenaires',
  },
  {
    Icon: CalculateOutline,
    to: '/comparateur',
    label: 'Comparateur',
  },
  {
    Icon: LittleStarsOutline,
    to: '/trombinoscope',
    label: 'Trombi',
  },
  {
    Icon: Invoice,
    to: '/gestion-facturation',
    label: 'Gestion facturation',
    show: (permissions: Permissions) => permissions.canManageFacturation,
  },
  {
    Icon: Invoice,
    to: '/cdpl-facturation',
    label: 'Ma facturation',
    show: (permissions: Permissions) => permissions.isCDPL,
  },
];
