import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { COMMISSIONS_KEY } from './useCommissions';

const handleAdvanceStatusCommissions = (commissionUuids: string[]): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin/partners/commissions/advance-status`;
  return request(requestURL, {
    method: 'POST',
    body: {
      commissionUuids,
    },
  });
};

export const useAdvanceStatusCommissions = (): {
  advanceStatusCommissions: typeof handleAdvanceStatusCommissions;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: advanceStatusCommissions, isPending } = useMutation({
    mutationFn: handleAdvanceStatusCommissions,
    onSuccess: () => {
      toast.success('Le changement de statut a été effectué avec succès');
      queryClient.invalidateQueries({ queryKey: [COMMISSIONS_KEY] });
    },
    onError: () => {
      toast.error('Le changement de statut a échoué');
    },
  });

  return { advanceStatusCommissions, isPending };
};
