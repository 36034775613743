import React from 'react';

import classNames from 'classnames';

import { DenseSizes } from './types';

import { InlineAlert } from '../InlineAlert';

const filledInputClassName = 'top-[3px] text-sm';
const focusedInput = 'peer-focus:!top-[3px] peer-focus:!text-sm';
const placeholderVisible = 'peer-placeholder-shown:!top-[3px] peer-placeholder-shown:!text-sm';
const emptyInputClassName = `top-[13px] text-b1 ${focusedInput} ${placeholderVisible}`;

const borderClassName = 'border-neutral-300 hover:border-neutral-600 focus-within:!border-primary';
const disabledClassName = 'bg-neutral-100 !text-neutral-300';
const placeholderClassName = 'placeholder:text-neutral-300 placeholder:opacity-100';

export const iconSuffixClassName = 'w-lg flex shrink-0 text-neutral-700 items-center';
export const textSuffixClassName =
  'flex items-center text-b1 font-medium text-neutral-700 whitespace-nowrap';

export const makeInputContainerClassnames = ({
  disabled,
  error,
}: {
  disabled?: boolean;
  error?: string;
}): string =>
  classNames(
    error ? 'border-error' : borderClassName,
    disabled ? classNames(disabledClassName, 'hover:border-neutral-300') : 'bg-white',
    'relative pr-sm border border-solid flex rounded',
  );

export const makeInputMainClassnames = ({
  disabled,
  hasPrefix,
  hasLabel,
  dense,
}: {
  disabled?: boolean;
  hasPrefix?: boolean;
  hasLabel?: boolean;
  dense?: DenseSizes;
  isTextArea?: boolean;
}): string =>
  classNames(
    placeholderClassName,
    { 'placeholder:text-neutral-600': dense && !disabled },
    { [classNames(disabledClassName)]: disabled },
    { '!py-[3px]': dense === 'md' },
    { '!py-[1px] !text-b2': dense === 'sm' },
    // We use margin top here to permit TextArea to scroll correctly when it needs to
    hasLabel ? 'mt-[18px] pt-0 pb-xxs' : 'py-sm',
    hasPrefix ? 'pl-xxs' : 'pl-md',
    dense ? '!pl-xs !pr-xxs' : 'pr-0',
    'w-full mr-xs peer border-0 outline-none text-b1 text-neutral-800 rounded',
  );

export const makeInputLabelClassnames = ({
  value,
  disabled,
  hasPrefix,
}: {
  value?: string;
  disabled?: boolean;
  hasPrefix?: boolean;
}): string =>
  classNames(
    value || hasPrefix ? filledInputClassName : emptyInputClassName,
    { [disabledClassName]: disabled },
    'absolute pl-md transition-all text-neutral-600',
  );

interface InputHelperTextProps {
  helperText?: string | React.ReactElement;
}
export const InputHelperText: React.FC<InputHelperTextProps> = ({ helperText }) =>
  helperText ? (
    <span className="mt-xxs text-sm text-neutral-600 inline-block">{helperText}</span>
  ) : null;

interface InputErrorTextProps {
  error?: string;
}
export const InputErrorText: React.FC<InputErrorTextProps> = ({ error }) =>
  error ? (
    <div className="mt-xxs">
      <InlineAlert level="error">{error}</InlineAlert>
    </div>
  ) : null;
