import { PartnerCommission } from 'types';

export const shouldDisableTableRow = (item: PartnerCommission): boolean => {
  if (item.archived) {
    return true;
  }
  const hasNotBeenSent = ['to_send', 'pending'].includes(item.status);
  const isClassicalCommission = item.typeCommission === 'pourcentage';

  if (hasNotBeenSent && isClassicalCommission && !item.billingEmail) {
    return true;
  }

  const isInBillingStep = item.status === 'to_bill';
  const factureHasNotBeenUploaded = !item.factureUrl;

  if (isInBillingStep && isClassicalCommission && factureHasNotBeenUploaded) {
    return true;
  }
  return false;
};
