import React from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import yn from 'yn';

import { HelmetProvider } from '@travauxlib/shared/src/components/Helmet';
import { ScrollToTop } from '@travauxlib/shared/src/components/ScrollToTop';
import { initTracking } from '@travauxlib/shared/src/lib/TrackingCore';
import { initDayjs } from '@travauxlib/shared/src/utils/time';

import '@fontsource/noto-sans/latin.css';
import 'index.css';

import { App } from './App';

initTracking({
  environment: ENVIRONMENT,
  token: APP_CONFIG.mixpanel.token,
  enabled: APP_CONFIG.mixpanel.enabled,
});
initDayjs();

if (ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://514a130e263cb9b2ea6b0e73b7b611cf@o4506036781449216.ingest.sentry.io/4506100326989824',
    environment: ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration({}), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient();

const withReactQueryDevTool = yn(import.meta.env.VITE_SHOW_REACT_QUERY);
const noStrictMode = yn(import.meta.env.VITE_NO_STRICT_MODE);

const root = createRoot(document.getElementById('root')!);

const Content: React.FC = () => (
  <GoogleOAuthProvider clientId={APP_CONFIG.googleClientId}>
    <BrowserRouter>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <App />
          {withReactQueryDevTool && <ReactQueryDevtools />}
        </QueryClientProvider>
      </HelmetProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

root.render(
  noStrictMode ? (
    <Content />
  ) : (
    <React.StrictMode>
      <Content />
    </React.StrictMode>
  ),
);
