import React from 'react';

import classNames from 'classnames';

import { BadgeSizes, BadgeVariant } from './types';
import { getClassNamesBySize, MAX_NOTIF, variantsClassNames } from './utils';

export type Props = {
  className?: string;
  variant?: BadgeVariant;
  size?: BadgeSizes;
  value: number;
};

export const Badge: React.FC<Props> = ({ className, size = 'md', variant = 'light', value }) => (
  <div
    data-testid="badge"
    className={classNames(
      className,
      'rounded-full flex items-center justify-center shrink-0',
      variantsClassNames[variant],
      getClassNamesBySize(value)[size],
    )}
  >
    {size !== 'xs' ? (
      <div
        className={classNames('font-medium', {
          'text-b2': size === 'md',
          'text-sm': size === 'sm',
        })}
      >
        {value > MAX_NOTIF ? '99+' : value}
      </div>
    ) : null}
  </div>
);
