// Common events

export enum TrackingEvents {
  Added = 'Added',
  Archived = 'Archived',
  Clicked = 'Clicked',
  Closed = 'Closed',
  Completed = 'Completed',
  Created = 'Created',
  DevisPersonalized = 'DevisPersonalized',
  Declined = 'Declined',
  Downloaded = 'Downloaded',
  Edited = 'Edited',
  Favourited = 'Favourited',
  IntervalChanged = 'IntervalChanged',
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
  Opened = 'Opened',
  Restored = 'Restored',
  Released = 'Released',
  Sent = 'Sent',
  ShortcutUsed = 'ShortcutUsed',
  StatusChanged = 'StatusChanged',
  Stopped = 'Stopped',
  Updated = 'Updated',
  Used = 'User',
  Viewed = 'Viewed',
  Started = 'Started',
  Ended = 'Ended',
  Deleted = 'Deleted',
  Validated = 'Validated',
  Skipped = 'Skipped',
}

// App tracking

export enum AppFeatures {
  Documents = 'Documents',
  EspaceClient = 'EspaceClient',
  GuidesClient = 'GuidesClient',
  DevisClient = 'DevisClient',
  AvancementChantier = 'AvancementChantier',
  OffreFinancement = 'OffreFinancement',
  CustomerSatisfactionPoll = 'CustomerSatisfactionPoll',
  DocumentFinChantier = 'DocumentFinChantier',
  PvReception = 'PVReception',
  PvLeveeReserves = 'PVLeveeReserves',
}

export enum EspaceClientSubFeatures {
  Action = 'Action',
  Home = 'Home',
  Chantiers = 'Chantiers',
  Documents = 'Documents',
  Guides = 'Guides',
  AskForHelp = 'AskForHelp',
  DocumentsExplanation = 'DocumentsExplanation',
  EstimateProjectCTA = 'EstimateProjectCTA',
  EstimateProjectPage = 'EstimateProjectPage',
  InvoicesAndBills = 'InvoicesAndBills',
  Payment = 'Payment',
  ProjectName = 'ProjectName',
  ProjectStep = 'ProjectStep',
  ReferralCTA = 'ReferralCTA',
  ReferralPage = 'ReferralPage',
}

export enum GuidesClientSubFeatures {
  Accompagnements = 'Accompagnements',
  AccompagnementTab = 'AccompagnementTab',
  AchatFournitures = 'AchatFournitures',
  Architectes = 'Architectes',
  ArchitecteTab = 'ArchitecteTab',
  Concept = 'Concept',
  CompteSequestre = 'CompteSequestre',
  DebuterProjetTab = 'DebuterProjetTab',
  Demarrage = 'Demarrage',
  Garanties = 'Garanties',
  Interlocuteurs = 'Interlocuteurs',
  PrixDevis = 'PrixDevis',
  Reception = ' Reception',
  RedirectionLink = 'RedirectionLink',
  ServicesHemea = 'ServicesHemea',
  SuiviChantier = 'SuiviChantier',
  SuivreSonProjetTab = 'SuivreSonProjetTab',
}

export enum DevisClientSubFeatures {
  AfficherLesModifications = 'AfficherLesModifications',
  Signature = 'Signature',
  Comments = 'Comments',
}

export enum OffreFinancementSubFeatures {
  Detail = 'Detail',
  JeSuisInteresse = 'JeSuisInteresse',
}

export enum EspaceClientEventProperties {
  ProjectUuid = 'Project Uuid',
  ProjectName = 'Project Name',
  ProjectPhase = 'Project Phase',
  Empty = 'Empty',
  DevisToken = 'Devis Token',
  DevisPrixTotalTTC = 'Devis Prix Total TTC',
  CustomerSatisfactionCondition = 'Customer Satisfaction Condition',
  CustomerSatisfactionScore = 'Customer Satisfaction Score',
  CustomerSatisfactionType = 'Customer Satisfaction Type',
  CustomerSatisfactionFeature = 'Customer Satisfaction Feature',
  CustomerSatisfactionSubFeature = 'Customer Satisfaction Subfeature',
  ActionType = 'Action Type',
  ActionTitle = 'Action Title',
}

export enum GuidesClientEventProperties {
  ParentGuideSection = 'Parent Guide Section',
  PageContainingLink = 'Page Containing Link',
}

export enum DevisClientEventProperties {
  DevisToken = 'Devis Token',
}

// Pro tracking

export enum ClubProFeatures {
  Onboarding = 'Onboarding',
  Registration = 'Registration',
  Identity = 'Identity',
  Devis = 'ProDevis',
  Facture = 'Facture',
  SuiviDeChantier = 'SuiviDeChantier',
  BonDeCommande = 'BonDeCommande',
  Notification = 'Notification',
  Parrainage = 'Parrainage',
  CentraleAchat = 'CentraleAchat',
  BaseDePrix = 'BaseDePrix',
  ClientChantier = 'ClientChantier',
  Dashboard = 'Dashboard',
  LDD = 'LDD',
  Contact = 'Contact',
  Prestataires = 'Prestataires',
  Parametres = 'Parametres',
  Utilisateurs = 'Utilisateurs',
  NewRegistration = 'NewRegistration',
  DemandeChantier = 'DemandeChantier',
  FinChantier = 'Fin Chantier',
  DocumentFinChantier = 'DocumentFinChantier',
  AvancementChantier = 'AvancementChantier',
}

export enum BaseDePrixSubFeatures {
  Ligne = 'Ligne',
  MainOeuvre = 'MainOeuvre',
  MateriauxConsommable = 'MateriauxConsommable',
  FournituresEquipements = 'FournituresEquipements',
}

export enum DashboardSubFeatures {
  Devis = 'Devis',
  Facture = 'Facture',
  Graph = 'Graph',
}

export enum DevisSubFeatures {
  Modal = 'Modal',
}

export enum FactureSubFeatures {
  Modal = 'Modal',
}

export enum LDDSubFeatures {
  Ligne = 'Ligne',
  LigneFourniture = 'LigneFourniture',
  TDCLigne = 'TDCLigne',
  Session = 'Session',
  Ouvrage = 'Ouvrage',
}

export enum NotificationSubFeatures {
  All = 'All',
  Tab = 'Tab',
}

export enum OnboardingSubFeatures {
  CentraleAchat = 'CentraleAchat',
  ConfirmationSubscription = 'ConfirmationSubscription',
  Contact = 'Contact',
  Demo = 'Demo',
  Interests = 'Interests',
  Insurance = 'Insurance',
  Software = 'Software',
}

export enum ParametresSubFeatures {
  Utilisateurs = 'Utilisateurs',
  MonEntreprise = 'MonEntreprise',
}

export enum RegistrationSubFeatures {
  Account = 'Account',
  Company = 'Company',
  Interests = 'Interests',
  Siret = 'Siret',
}

// Admin Tracking

export enum AdminFeatures {
  Navigation = 'Navigation',
  Identity = 'Identity',
  DashboardCDP = 'DashboardCPD',
  InscriptionsPro = 'InscriptionsPro',
  Consultations = 'Consultations',
  Entreprises = 'Entreprises',
  Leads = 'Leads',
  ComptesClients = 'ComptesClients',
  ConsultationInfo = 'ConsultationInfo',
  Partenaires = 'Partenaires',
  Comparateur = 'Comparateur',
  Trombi = 'Trombi',
  FinChantier = 'FinChantier',
}

export enum AdminPartenairesSubfeatures {
  Compte = 'Compte',
  Agence = 'Agence',
  Organisation = 'Organisation',
}

export enum FicheProjetSubfeatures {
  Consultation = 'Consultation',
  Prechiffrage = 'Prechiffrage',
  Configuration = 'Configuration',
  Document = 'Document',
  Info = 'Info',
}

export enum FinChantierSubfeatures {
  Threshold = 'Threshold',
}

export enum ConsultationInfoSubfeatures {
  Chantier = 'Chantier',
  InfoChantier = 'InfoChantier',
  Fonds = 'Fonds',
}

export enum AdminEventsProperties {
  UserRole = 'User Role',
  Email = 'Email',
  OrganisationPartenaireUUID = 'Organisation Partenaire UUID',
  AgencePartenaireUUID = 'Agence Partenaire UUID',
  ComptePartenaireUUID = 'Compte Partenaire UUID',
  ClientUUID = 'Client UUID',
  ChantierUUID = 'Chantier UUID',
  ProjetUUID = 'Projet UUID',
  ProjetName = 'Projet Name',
  DealUUID = 'Deal UUID',
  LeadSource = 'Lead Source',
  ClientFirstname = 'Client Firstname',
  ClientLastname = 'Client Lastname',
  ClientTelephoneNumber = 'Client Telephone Number',
  ClientEmail = 'Client Email',
}

export enum EndChantierEventProperties {
  EndChantierThresholdType = 'End Chantier Threshold type',
  EndChantierThresholdOldValue = 'End Chantier Threshold Old Value',
  EndChantierThresholdNewValue = 'End Chantier Threshold New Value',
}

export enum EndChantierThresholdType {
  ReceptionChantier = 'Reception Chantier',
  LeveeReserves = 'Levee Reserves',
}

export enum PvLeveeReservesProperties {
  NbOfDaysSinceReception = 'Nb of Days Since Reception',
}

export enum PvReceptionProperties {
  PvReceptionAvecReserves = 'PV Reception Avec Reserves',
}

export enum DocumentFinChantierProperties {
  EndOfChantierDocumentType = 'End of Chantier Document Type',
  NumberOfDocumentsUploaded = 'Number of Documents Uploaded',
}
