import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  EndChantierEventProperties,
  AdminFeatures,
  FinChantierSubfeatures,
  TrackingEvents,
  EndChantierThresholdType,
} from '@travauxlib/shared/src/utils/tracking';

class FinChantierClass extends Tracker<AdminFeatures> {
  onThresholdModified(properties: {
    [EndChantierEventProperties.EndChantierThresholdType]: EndChantierThresholdType;
    [EndChantierEventProperties.EndChantierThresholdOldValue]: number;
    [EndChantierEventProperties.EndChantierThresholdNewValue]: number;
  }): void {
    this.sendTracking({
      subFeature: FinChantierSubfeatures.Threshold,
      event: TrackingEvents.Edited,
      properties,
    });
  }
}

export const FinChantier = new FinChantierClass(AdminFeatures.FinChantier);
