import { googleLogout, TokenResponse } from '@react-oauth/google';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { PersonConfig, PersonRole, TeamsLabel } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { Ability } from 'lib/ability/AbilityBuilder';
import { defineAbilityFor } from 'lib/ability/defineAbilityFor';
import { Action, Flag, Subject } from 'lib/ability/entities/types';

export type FetchedProfile = {
  uuid: string;
  firstName?: string;
  lastName?: string;
  adminEmail: string;
  role?: PersonRole;
  teams: TeamsLabel[];
  config?: PersonConfig;
  isConnectedAsClient: boolean;
  isConnectedAsPro: boolean;
};

const getProfile = (): Promise<FetchedProfile> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin-profile`;
  return request(requestURL);
};

export const AUTH_KEY = 'auth';

export type AdminUserProfile = {
  uuid?: string;
  firstName?: string;
  lastName?: string;
  adminEmail?: string;
  role: PersonRole;
  config?: PersonConfig;
  isMemberOfAny: (teams: TeamsLabel[]) => boolean;
  isConnectedAsClient?: boolean;
  isConnectedAsPro?: boolean;
};

export const useAuth = (): {
  isLoading: boolean;
  adminUserProfile: AdminUserProfile;
  ability: Ability<Action, Subject, Flag>;
} => {
  const { data, isLoading } = useQuery<FetchedProfile>({
    queryKey: [AUTH_KEY],
    queryFn: getProfile,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: (failureCount, error: AxiosError) => failureCount < 2 && error?.response?.status !== 404,
  });

  const teams = data?.teams || [];
  const ability = defineAbilityFor(data?.role);

  return {
    adminUserProfile: {
      ...data,
      role: data?.role || PersonRole.NoRole,
      isMemberOfAny: (targetTeams: TeamsLabel[]) => targetTeams.some(t => teams.includes(t)),
    },
    ability,
    isLoading,
  };
};

const handleLogin = ({ access_token }: TokenResponse): Promise<FetchedProfile> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin-login`;
  return request(requestURL, {
    method: 'POST',
    body: {
      accessToken: access_token,
    },
  });
};

export const useLogin = (): typeof handleLogin => {
  const queryClient = useQueryClient();
  const { mutateAsync: login } = useMutation({
    mutationFn: handleLogin,
    onSuccess: data => {
      toast.success('Vous êtes authentifié');
      queryClient.setQueryData([AUTH_KEY], data);
    },
    onError: e => {
      toast.error(`Erreur au moment de l'authentification: ${(e as Error).message}`);
    },
  });

  return login;
};

const handlePasswordLogin = ({ password }: { password: string }): Promise<FetchedProfile> => {
  const encodedPassword = encodeURIComponent(password);
  const requestURL = `${APP_CONFIG.apiURL}/admin-login-password?password=${encodedPassword}`;
  return request(requestURL, {
    method: 'POST',
  });
};

export const usePasswordLogin = (): typeof handlePasswordLogin => {
  const queryClient = useQueryClient();
  const { mutateAsync: passwordLogin } = useMutation({
    mutationFn: handlePasswordLogin,
    onSuccess: data => {
      queryClient.setQueryData([AUTH_KEY], data);
    },
  });
  return passwordLogin;
};

const handleLogout = (): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/admin-logout`;
  googleLogout();
  return request(requestURL, {
    method: 'POST',
  });
};

export const useLogout = (): typeof handleLogout => {
  const queryClient = useQueryClient();
  const { mutateAsync: logout } = useMutation({
    mutationFn: handleLogout,
    onSuccess: () => {
      queryClient.setQueryData([AUTH_KEY], null);
    },
  });

  return logout;
};
