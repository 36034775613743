import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { CDPLFacture } from '../types';

type APIResponse = {
  data: CDPLFacture[];
};

const fetchInvoices = (): Promise<APIResponse> =>
  request(`${APP_CONFIG.apiURL}/admin/cdpl/me/invoices `);

export const useMyCdplInvoices = (): {
  isLoading: boolean;
  invoices: CDPLFacture[];
} => {
  const { isLoading, data } = useQuery<APIResponse>({
    queryKey: ['CDPL_INVOICES_ME'],
    queryFn: fetchInvoices,
  });

  return { isLoading, invoices: data?.data || [] };
};
