import React, { useRef } from 'react';

import { Placement } from '@floating-ui/react';
import classNames from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { useScrollStatus } from '@travauxlib/shared/src/hooks/useScrollStatus';
import {
  scrollToNextHiddenChildren,
  scrollToPreviousHiddenChildren,
} from '@travauxlib/shared/src/utils/scroll';

import { Tab, TabsPosition, TabsSize } from './types';
import {
  makeOverflowButtonClassNames,
  makeTabItemClassName,
  makeTabLinkActiveClassName,
  makeTabLinkClassName,
  makeTabsContainerBottomBorderClassNames,
  makeTabsContainerClassName,
} from './utils';

import { ActionDisabledWithTooltip } from '../Buttons/ActionDisabledWithTooltip';
import { IconButton } from '../Buttons/IconButton';

type Props = {
  items: Tab[];
  size?: TabsSize;
  fullWidth?: boolean;
  position?: TabsPosition;
  buttonClassNames?: string;
  className?: string;
  tooltipPosition?: Placement;
  hideContainerBottomBorder?: boolean;
};

export const Tabs: React.FC<Props> = ({
  items,
  size = 'md',
  fullWidth = false,
  position = 'left',
  buttonClassNames = 'bg-white',
  className,
  tooltipPosition = 'bottom',
  hideContainerBottomBorder = false,
}) => {
  const location = useLocation();
  const scrollingContainer = useRef<HTMLUListElement>(null);
  const previousTabsButton = useRef<HTMLDivElement>(null);
  const nextTabsButton = useRef<HTMLDivElement>(null);
  const {
    isScrollLeft,
    isScrollRight: rightEdgeReached,
    hasHorizontalScroll,
  } = useScrollStatus(scrollingContainer);

  const handleNextTabsButtonClick = scrollToNextHiddenChildren(scrollingContainer, nextTabsButton);

  const handlePreviousTabsButtonClick = scrollToPreviousHiddenChildren(
    scrollingContainer,
    previousTabsButton,
  );

  /*
   * Check if selected tab is partially hidden and automatically scroll to make it visible
   */
  const onTabClick = (e: React.MouseEvent<HTMLLIElement>): void => {
    if (!hasHorizontalScroll || !scrollingContainer.current) {
      return;
    }
    const rightBound =
      nextTabsButton.current?.getBoundingClientRect().left ||
      scrollingContainer.current.getBoundingClientRect().right;
    const leftBound =
      previousTabsButton.current?.getBoundingClientRect().right ||
      scrollingContainer.current.getBoundingClientRect().left;
    const selectedTabBoundingClientRect = (
      e.currentTarget as HTMLLIElement
    ).getBoundingClientRect();
    const selectedTabRightBound = Math.round(selectedTabBoundingClientRect.right);
    const selectedTabLeftBound = Math.round(selectedTabBoundingClientRect.left);
    const isSelectedTabCompletelyVisible =
      selectedTabRightBound <= rightBound && selectedTabLeftBound >= leftBound;
    if (isSelectedTabCompletelyVisible) {
      return;
    }
    if (selectedTabRightBound > rightBound) {
      const scrollAmount = selectedTabRightBound - rightBound;
      scrollingContainer.current?.scrollBy({ top: 0, left: scrollAmount, behavior: 'smooth' });
    } else {
      const scrollAmount = leftBound - selectedTabLeftBound;
      scrollingContainer.current?.scrollBy({ top: 0, left: -scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className={classNames('flex items-center relative w-full', className)}>
      {!isScrollLeft && (
        <div
          ref={previousTabsButton}
          className={makeOverflowButtonClassNames({
            buttonClassNames,
            size,
            buttonPosition: 'left',
          })}
        >
          <IconButton onClick={handlePreviousTabsButtonClick} size={size}>
            <ChevronLeft />
          </IconButton>
        </div>
      )}
      <div
        className={classNames(
          'overflow-x-auto',
          !hideContainerBottomBorder && makeTabsContainerBottomBorderClassNames({ size }),
        )}
      >
        <ul ref={scrollingContainer} className={makeTabsContainerClassName({ fullWidth })}>
          {items.map(
            ({
              label,
              to,
              badge,
              onClick,
              icon,
              disabled = false,
              disabledMessage,
              end,
              isActive,
              className,
            }) => {
              const content = (
                <>
                  {icon && (
                    <div className="shrink-0 flex justify-center items-center w-lg h-lg mr-xxs">
                      {icon}
                    </div>
                  )}
                  <div
                    className={classNames(
                      'truncate',
                      !!badge && size === 'md' && 'mr-xs',
                      !!badge && size === 'sm' && 'mr-xxs',
                    )}
                  >
                    {label}
                  </div>
                  {!!badge && <Badge size={size} variant="error" {...badge} />}
                </>
              );
              return (
                <li
                  data-testid={label}
                  key={label}
                  className={classNames(makeTabItemClassName({ fullWidth, position }), className)}
                  onClick={onTabClick}
                >
                  <ActionDisabledWithTooltip
                    disabled={disabled}
                    position={tooltipPosition}
                    disabledMessage={disabledMessage}
                  >
                    {isActive ? (
                      <Link
                        onClick={e => {
                          if (disabled) {
                            e.preventDefault();
                          } else {
                            onClick?.();
                          }
                        }}
                        to={to}
                        className={classNames(makeTabLinkClassName({ size, disabled }), {
                          [makeTabLinkActiveClassName({ size })]: isActive(location),
                        })}
                      >
                        {content}
                      </Link>
                    ) : (
                      <NavLink
                        end={end}
                        onClick={e => {
                          if (disabled) {
                            e.preventDefault();
                          } else {
                            onClick?.();
                          }
                        }}
                        to={to}
                        className={({ isActive }) =>
                          classNames(makeTabLinkClassName({ size, disabled }), {
                            [makeTabLinkActiveClassName({ size })]: isActive,
                          })
                        }
                      >
                        {content}
                      </NavLink>
                    )}
                  </ActionDisabledWithTooltip>
                </li>
              );
            },
          )}
        </ul>
      </div>
      {hasHorizontalScroll && !rightEdgeReached && (
        <div
          ref={nextTabsButton}
          className={makeOverflowButtonClassNames({
            buttonClassNames,
            size,
            buttonPosition: 'right',
          })}
        >
          <IconButton onClick={handleNextTabsButtonClick} size={size}>
            <ChevronRight />
          </IconButton>
        </div>
      )}
    </div>
  );
};
