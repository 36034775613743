import React from 'react';

import { Person } from '@travauxlib/shared/src/types';
import { PersonRole } from '@travauxlib/shared/src/types';

import { PeopleSelector } from 'components/PeopleSelector';
import { LeadHistory } from 'features/DashboardCDP/components/CDPDashboardLine/LeadHistory';
import { useAttribuableEmployees } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { Lead } from 'types';

import { LeadUpdater } from './LeadUpdater';

import { useUpdateLead } from '../api/useUpdateLead';

type Props = {
  lead: Lead;
};

export const LeadAction: React.FC<Props> = ({ lead }) => {
  const { persons: attribuableEmployees, isLoading } = useAttribuableEmployees();

  const { close, updateStatus, setDealOwner, updateAdressable, isUpdating } = useUpdateLead();
  const isAllowedToManageLead = useIsAllowed('Lead', 'manageAttribution');

  // When a non-sale (ex. admin) closes the deal, he's auto assigned.
  // Since he's not in the list, the hack is to show him as an additional option,
  // otherwise the re-render triggers an unwanted deal owner change

  if (isLoading) {
    return null;
  }

  const isDealOwnerInSalesTeam = attribuableEmployees.some(s => s.email === lead.dealOwner);
  const additionalOption: Person[] =
    lead.dealOwner && !isDealOwnerInSalesTeam
      ? [{ email: lead.dealOwner, firstName: 'CDP Non Ops', lastName: lead.dealOwner } as Person]
      : [];

  const isAssignedToCDPL =
    !!lead.dealOwner &&
    attribuableEmployees.some(e => e.email === lead.dealOwner && e.role === PersonRole.CDPL);

  return (
    <>
      <div className="mb-xs">
        <PeopleSelector
          id={`dealOwner-${lead.token}`}
          persons={additionalOption.concat(attribuableEmployees)}
          value={lead.dealOwner}
          allowEmpty={!lead.dealOwner}
          emptyLabel="Aucun"
          onChange={dealOwner => dealOwner && setDealOwner({ lead, dealOwner })}
          disabled={!isAllowedToManageLead}
        />
      </div>
      <LeadUpdater
        lead={lead}
        handleCloseDemande={close}
        handleUpdateAdressable={updateAdressable}
        handleUpdateStatus={updateStatus}
        isUpdating={isUpdating}
        isAssignedToCDPL={isAssignedToCDPL}
      />
      <LeadHistory lead={lead} />
    </>
  );
};
