import { stringifyParams } from '@travauxlib/shared/src/utils/urls';

import { Devis } from 'types';
import { statusATraiter } from 'utils/constants';

const apiUrl = APP_CONFIG.apiURL;

export const appUrls = {
  pageVitrine: (slug: string) => `${APP_CONFIG.appURL}/entreprises/${slug}`,
  homePartenaire: (token: string) => `${APP_CONFIG.appURL}/partner/${token}`,
};

export const adminUrls = {
  leadsUrl: `/dashboard-cdp?${stringifyParams({
    status: [statusATraiter],
  })}`,
  editCompanyUrl: (slug: string) => `/entreprises/${slug}`,
  suiviChantierHistoryUrl: (uuid: string) => `suivi-chantier/${uuid}/history`,
  partnerUrl: '/partners',
  proRegistrations: '/inscriptions-pro',
  newLeadPro: '/inscriptions-pro/new-lead',
  searchCompany: '/entreprises/details',
  searchClubProCompany: '/entreprises/club-pro',
  checkCompaniesStatus: '/entreprises/status',
  ficheProjetUrl: (uuid: string) => `/projects/${uuid}/information`,
};

export const apiUrlsBuilder = {
  facture: {
    pdfUrl: (token: string) => `${apiUrl}/factures/${token}/pdf`,
  },
  proCompanies: {
    baseDePrixCsv: (slug: string) => `${apiUrl}/admin/pro-companies/${slug}/base-de-prix`,
    patchProCompany: (slug: string) => `${apiUrl}/admin/pro-companies/${slug}`,
    photos: (slug: string) => `${apiUrl}/admin/pro-companies/${slug}/projects/photos`,
    toggleFreeze: (slug: string) => `${apiUrl}/admin/pro-companies/${slug}/toggle-freeze`,
  },
  admin: {
    resetPassword: (userId: number) => `${apiUrl}/admin/pro-users/${userId}/reset-password`,
    sendConfirmationEmail: (userId: number) =>
      `${apiUrl}/admin/pro-users/${userId}/send-confirmation-email`,
  },
};

export const clicDataScoreURL = 'https://hemea.clicdata.com/v/dev';

export const getLinkDevisRO = (devis: Devis): string =>
  `${APP_CONFIG.appURL}/pro/devis/${devis.token}?isAuthor`;
