// % is the remainder operator, not the modulo
// spoiler: it sucks
// https://stackoverflow.com/a/17323608/4949918

export const mod = (n: number, mod: number): number => ((n % mod) + mod) % mod;

export const calculatePercentage = (numerator: number, denominator: number): number => {
  if (numerator === 0 && denominator === 0) {
    return 100;
  }

  if (denominator === 0) {
    return 0; // Return 0% if the total is 0 to avoid division by zero
  }

  return Math.min(100, Math.floor((numerator / denominator) * 100));
};
