import React, { useEffect } from 'react';

import { autoUpdate, useFloating } from '@floating-ui/react';

import { isDeletedThread } from '@travauxlib/shared/src/features/DevisDisplay/utils/isDeletedThread';
import { DevisComment } from '@travauxlib/shared/src/types';

import { CommentActions } from './CommentActions';
import { DisplayedComment } from './DisplayedComment';
import { InputForm } from './InputForm';
import { TextareaForm } from './TextareaForm';
import { ThreadActions } from './ThreadActions';

import { useRevisionStore } from '../../hooks/useRevisionStore';
import { useDevisDisplayContext } from '../DevisDisplayContext';

type Props = {
  targetUuid: string;
  threadUuid?: string;
};

export const FloatingComments: React.FC<Props> = ({ targetUuid, threadUuid }) => {
  const { comments, lots, updateComment, addComment, senderNames, senderType, deleteComment } =
    useDevisDisplayContext();
  const { activeThread, editThread, openThread, closeThread, setIsRevising } = useRevisionStore();

  const isOpen =
    activeThread?.ligneUuid === targetUuid ||
    (threadUuid && activeThread?.threadUuid === threadUuid);

  const commentsList = comments?.[threadUuid!] || [];

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    transform: false,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    let timeoutId: number | undefined;

    if (isOpen) {
      const target = refs.reference.current as HTMLDivElement;

      timeoutId = window.setTimeout(() => {
        target?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 100);
    }

    //clear the timeout
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isOpen]);

  const lastComment = commentsList[commentsList.length - 1];

  const onSubmit = async (text: string, comment: DevisComment): Promise<void> => {
    if (comment) {
      await updateComment?.({ ...comment, targetUuid, text });
      openThread({ ligneUuid: targetUuid, threadUuid });
    } else {
      await addComment?.({ targetUuid, text, senderType, threadUuid });
      setIsRevising(true);
    }
  };
  if (commentsList.length === 0 || (commentsList.length === 1 && activeThread?.isEditing)) {
    return (
      <div
        onClick={e => {
          e.stopPropagation();
        }}
        className="absolute w-full cursor-default"
        ref={refs.setReference}
      >
        <div
          ref={refs.setFloating}
          //eslint-disable-next-line
          style={floatingStyles}
          className="translate-x-[calc(100%_-_1rem)] -mt-xs z-30 bg-white w-[20rem] shadow-xl rounded-xs pb-sm onboarding-ligne-comment"
        >
          <TextareaForm
            comment={commentsList[0]}
            className="pr-sm pt-xs pl-md"
            onSubmit={onSubmit}
            senderName={senderNames[senderType]}
            senderType={senderType}
            onClose={closeThread}
          />
        </div>
      </div>
    );
  }

  const shouldDisplayAnswerInput =
    lastComment &&
    lastComment.senderType === 'pro' &&
    lots
      .flatMap(lot => lot.items)
      .some(item => item.type === 'ligne' && item.threadUuid === lastComment.threadUuid) &&
    !lastComment.validated;

  return (
    <div
      onClick={e => e.stopPropagation()}
      className="absolute w-full cursor-default"
      ref={refs.setReference}
    >
      <div
        ref={refs.setFloating}
        //eslint-disable-next-line
        style={floatingStyles}
        className="translate-x-[calc(100%_-_1rem)] -mt-xs z-30 bg-white w-[20rem] shadow-xl rounded-xs pb-sm"
      >
        {commentsList.map((comment, index) => {
          const senderName = senderNames[comment.senderType];

          const isEditing =
            index === commentsList.length - 1 &&
            comment.senderType === senderType &&
            activeThread?.isEditing;
          const isDeleted = isDeletedThread(lots, threadUuid);
          return (
            <React.Fragment key={comment.uuid}>
              {isEditing ? (
                <InputForm
                  onSubmit={onSubmit}
                  senderName={senderNames[senderType]}
                  comment={comment}
                />
              ) : (
                <DisplayedComment
                  className="mr-sm pt-xxs ml-md border-t first:border-0"
                  senderName={senderName}
                  comment={comment}
                  editComment={() => editThread({ ligneUuid: targetUuid, threadUuid })}
                  rightContent={
                    index === 0 ? (
                      <ThreadActions
                        threadUuid={threadUuid!}
                        onClose={closeThread}
                        lastComment={lastComment}
                        isDeleted={isDeleted}
                      />
                    ) : index === commentsList.length - 1 ? (
                      <CommentActions
                        comment={comment}
                        isDeleted={isDeleted}
                        editComment={() => editThread?.({ ligneUuid: targetUuid, threadUuid })}
                        deleteComment={() => deleteComment?.(comment)}
                      />
                    ) : null
                  }
                />
              )}
            </React.Fragment>
          );
        })}
        {shouldDisplayAnswerInput && (
          <InputForm onSubmit={onSubmit} senderName={senderNames[senderType]} />
        )}
      </div>
    </div>
  );
};
