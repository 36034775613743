import React from 'react';

import _partition from 'lodash/partition';
import { useSearchParams } from 'react-router-dom';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { FactureStatuses } from '@travauxlib/shared/src/types';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';

import { useMyCdplInvoices } from './api/useMyCdplInvoices';
import { CDPLFactureTable } from './components/CDPLFactureTable';
import { useInformationsFacturation } from './hooks/useInformationsFacturation';

export const CdplFacturation: React.FC = () => {
  const handleOpenInformationsFacturations = useInformationsFacturation();

  const { invoices, isLoading } = useMyCdplInvoices();
  const [searchParams] = useSearchParams();
  const currentStatus = searchParams.get('status') || 'encours';

  if (isLoading) {
    return <Loader />;
  }

  const [facturesEnCours, facturesReglees] = _partition(
    invoices,
    ({ status }) => status === FactureStatuses.draft || status === FactureStatuses.awaiting_payment,
  );

  return (
    <>
      <PageTitle title={PageTitleEnum.cdplFacturation} />
      <div className="flex grow justify-between items-center">
        <div className="text-h3 font-bold">Ma facturation</div>
        <Button
          leftIcon={<InfoCircle />}
          onClick={handleOpenInformationsFacturations}
          variant="secondary"
          size="md"
        >
          Informations facturations
        </Button>
      </div>
      <Tabs
        items={[
          {
            label: 'Factures en cours',
            to: { search: 'status=encours' },
            isActive: () => currentStatus === 'encours',
          },
          {
            label: 'Factures réglées',
            to: { search: 'status=reglees' },
            isActive: () => currentStatus === 'reglees',
          },
        ]}
      />
      {currentStatus === 'encours' ? (
        <CDPLFactureTable factures={facturesEnCours} showPerson={false} />
      ) : (
        <CDPLFactureTable
          factures={facturesReglees}
          status={FactureStatuses.paid}
          showPerson={false}
        />
      )}
    </>
  );
};
