import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { FactureStatuses } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { CDPL_INVOICES_KEY } from 'features/CdplFacturation/api/useCdplInvoices';

type UpdateStatusVariables = {
  tokens: string[];
  status: FactureStatuses;
};

export const useUpdateStatusCdplInvoice = (): {
  updateStatus: UseMutateAsyncFunction<void, Error, UpdateStatusVariables>;
  isPending: boolean;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateStatus, isPending } = useMutation({
    mutationFn: ({ tokens, status }: UpdateStatusVariables) =>
      request(`${APP_CONFIG.apiURL}/admin/cdpl/invoices/status`, {
        method: 'PATCH',
        body: {
          tokens,
          status,
        },
      }),
    onSuccess: () => {
      toast.success('Les factures ont bien été mises à jour');
      queryClient.invalidateQueries({ queryKey: [CDPL_INVOICES_KEY] });
    },
    onError: () => {
      toast.error('Impossible de mettre à jour le statut des factures');
    },
  });

  return { updateStatus, isPending };
};
