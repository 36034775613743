import React, { useEffect } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import _throttle from 'lodash/throttle';

import { ModalContextWrapper } from './ModalContext';
import { Props } from './types';
import { modalConfigBySize, makeFadeAnimationClassNames } from './utils';

import { LayoutGrid } from '../Layout';

// use the wrapper unless you know what you're doing
export const RawModal = React.forwardRef<
  HTMLDivElement,
  Props & {
    isBelowDesktopSM: boolean;
    isMounted: boolean;
  }
>(
  (
    {
      children,
      title,
      handleClose,
      isMounted,
      isBelowDesktopSM,
      validateAction,
      cancelAction,
      size = 'md',
      isScrollable = true,
      disableShadow,
      preserveBodyOverflow = false,
    },
    ref,
  ) => {
    const [isBottomShadowVisible, setIsBottomShadowVisible] = useState<boolean>(false);
    const [isTopShadowVisible, setIsTopShadowVisible] = useState<boolean>(false);

    const contentRef = useRef<HTMLDivElement>(null);

    const config = modalConfigBySize[isBelowDesktopSM ? 'tabletAndMobile' : 'desktop'];
    const modalMaxHeight = `calc(100vh - ${config.modalOuterMargin}px)`;

    const setShadowOnScroll = (): void => {
      if (contentRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = contentRef.current;
        const isScrollAtTop = scrollTop === 0;
        const isScrollAtBottom = scrollTop + clientHeight === scrollHeight;

        setIsTopShadowVisible(disableShadow !== 'top' && !isScrollAtTop);
        setIsBottomShadowVisible(disableShadow !== 'bottom' && !isScrollAtBottom);
      }
    };

    const throttledShadowOnScroll: () => void = _throttle(setShadowOnScroll, 100);

    const isBrowser = typeof window !== 'undefined';
    const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

    useIsomorphicLayoutEffect(() => {
      setShadowOnScroll();
      if (preserveBodyOverflow) {
        return;
      }
      document.body.style.overflow = 'hidden';

      return () => {
        if (preserveBodyOverflow) {
          return;
        }
        document.body.style.overflow = 'unset';
      };
    }, []);

    useIsomorphicLayoutEffect(() => {
      const resizeObserver = new ResizeObserver(throttledShadowOnScroll);

      if (contentRef.current) {
        resizeObserver.observe(contentRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    useIsomorphicLayoutEffect(() => {
      contentRef.current?.addEventListener('scroll', throttledShadowOnScroll);

      return () => {
        contentRef.current?.removeEventListener('scroll', throttledShadowOnScroll);
      };
    }, []);

    return (
      <ModalContextWrapper
        contentRef={contentRef}
        isScrollable={isScrollable}
        isTopShadowVisible={isTopShadowVisible}
        isBottomShadowVisible={isBottomShadowVisible}
        isBelowDesktopSM={isBelowDesktopSM}
        validateAction={validateAction}
        cancelAction={cancelAction}
        handleClose={handleClose}
        title={title}
        size={size}
        modalMaxHeight={modalMaxHeight}
      >
        <div
          className={classNames(
            'fixed inset-0 w-screen h-screen bg-neutral z-40',
            makeFadeAnimationClassNames(isMounted ? 'opacity-50' : undefined),
          )}
          ref={ref}
        />
        <div
          className={classNames('fixed z-50 left-0 w-full', config.makeClassNames(isMounted))}
          role="dialog"
          //eslint-disable-next-line
          style={{ maxHeight: modalMaxHeight }}
          aria-label={typeof title === 'string' ? title : 'Modal'}
        >
          <LayoutGrid className={classNames({ '!mx-0': isBelowDesktopSM })}>
            <>{children}</>
          </LayoutGrid>
        </div>
      </ModalContextWrapper>
    );
  },
);
