import React from 'react';

import classNames, { Argument } from 'classnames';

export type FormControlProps<T> = {
  value?: T;
  onChange?: (isChecked: T) => void;
  label?: string | React.ReactElement;
  indeterminate?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

export type FormControlListOption<T> = {
  value: T;
  label?: string;
  disabled?: boolean;
  default?: boolean;
  tooltip?: string;
};

export type FormControlListProps<GlobalValue, ItemValue = GlobalValue> = {
  className?: string;
  optionsClassName?: string;
  inline?: boolean;
  options: FormControlListOption<ItemValue>[];
  id?: string;
  disabled?: boolean;
  onChange: (value: GlobalValue) => void;
  value: GlobalValue;
  renderingOptions?: {
    containerClassName?: string;
    elementClassName?: string;
  };
  error?: string;
};

export type FormControlListOptionVerticalChip<T> = FormControlListOption<T> & {
  centerIcon: React.ReactElement;
};

export type FormControlListVerticalChipProps<GlobalValue, ItemValue = GlobalValue> = Omit<
  FormControlListProps<GlobalValue, ItemValue>,
  'options'
> & {
  options: FormControlListOptionVerticalChip<ItemValue>[];
};

export const commonFormControlContainerClasses = (
  checked?: boolean,
  disabled?: boolean,
  error?: boolean,
): Argument[] => [
  'outline outline-1 outline-solid outline-neutral-300 m-3xs text-neutral-0 peer-focus-visible:outline-2 peer-focus-visible:outline-info  group-active:outline-primary-600 group-focus:outline-info group-focus:outline-2 group-hover:outline-primary-500',
  { 'group-focus:outline-2 group-focus:outline-info': !disabled },
  { 'outline-primary-400': !disabled && checked },
  { '!outline-error': !disabled && error },
  { 'group-hover:outline-neutral-800 group-active:outline-primary-400': !disabled && !checked },
];

export const commonFormControlCheckmarkClasses = (
  checked?: boolean,
  disabled?: boolean,
  indeterminate?: boolean,
): Argument[] => [
  { 'bg-neutral-100': disabled && !checked },
  { 'bg-neutral-300': disabled && checked },
  {
    'bg-primary-400 group-hover:bg-primary-500 group-active:bg-primary-600':
      !disabled && (checked || indeterminate),
  },
];

export const hiddenInputClassNames = 'peer cursor-pointer absolute top-0 left-0 opacity-0';
export const containerLabelClassnames = (disabled?: boolean): Argument[] => [
  'text-b1 relative mb-0 min-h-lg flex cursor-pointer',
  { 'pointer-events-none': disabled },
];

type Props = FormControlProps<any>;
export const GenericFormControlContainer: React.FC<Props> = ({
  className,
  label,
  children,
  ...rest
}) => (
  <label className={classNames(className, containerLabelClassnames(rest.disabled))}>
    <div className="inline-flex items-center gap-xs group">
      <input className={hiddenInputClassNames} {...rest} />
      {children}
      {label ? <span>{label}</span> : null}
    </div>
  </label>
);
