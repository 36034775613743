import React from 'react';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { PersonRole } from '@travauxlib/shared/src/types';
import { CharteStatus, LabellisationStatus } from '@travauxlib/shared/src/types/company';
import { gammeOptions, proProfileFlags } from '@travauxlib/shared/src/utils/constants';
import { departments } from '@travauxlib/shared/src/utils/departments';
import { required } from '@travauxlib/shared/src/utils/form';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

import { CheckboxGroupedField } from 'components/CheckboxGrouped/Field';
import { usePersons } from 'features/Trombinoscope/api/usePersons';
import { useIsAllowed } from 'hooks/useIsAllowed';
import { ProCompany } from 'types';
import { companyTypes, labellisationStatusesById, travailEnInterne } from 'utils/constants';
import {
  ModeCalculCommission,
  modeCalculCommissionById,
} from 'utils/constants/calculCommissionModes';

import { FieldsArchitect } from './FieldsArchitect';
import { FieldsPro } from './FieldsPro';

const labellisationStatusOptions = Object.values(LabellisationStatus).map(id => ({
  value: id,
  label: labellisationStatusesById[id].label,
}));

const modeCalculCommissionOptions = Object.values(ModeCalculCommission).map(id => ({
  value: id,
  label: modeCalculCommissionById[id].label,
}));

type OwnProps = {
  companyType: ProCompany['companyType'];
  charteStatus: ProCompany['charteStatus'];
  status: LabellisationStatus;
  chiffresAffaires: ProCompany['chiffresAffaires'];
  archiType: ProCompany['archiType'];
  isExclusive: boolean;
};
const getHelperTextModeCommission = ({
  isArchitecte,
  isAllowedToUpdateCalculCommission,
  isExclusive,
}: {
  isArchitecte: boolean;
  isAllowedToUpdateCalculCommission: boolean;
  isExclusive: boolean;
}): string | undefined => {
  if (isExclusive) {
    return 'Le taux de commission est fixé à 20% pour une entreprise exclusive';
  }
  if (isArchitecte) {
    return "Le mode de calcul de commission n'est pas modifiable pour les architectes";
  }

  if (!isAllowedToUpdateCalculCommission) {
    return "Vous n'avez pas les droits pour modifier ce champ";
  }
};

export const FieldList: React.FC<OwnProps> = ({
  status,
  companyType,
  charteStatus,
  chiffresAffaires = {},
  archiType,
  isExclusive,
}) => {
  const isArchitecte = companyType === 'architecte';
  const { persons } = usePersons();
  const { persons: cdpl, isLoading } = usePersons([PersonRole.CDPL]);
  const isAllowedToUpdateCalculCommission = useIsAllowed('Chantier', 'changeCommission');
  const isAllowedToAssignRecruiterForCompany = useIsAllowed('Company', 'manage');

  if (isLoading) {
    return <Loader />;
  }

  const cdplOptions = cdpl.map(p => ({
    label: `${p.firstName} ${p.lastName}`,
    value: p.uuid,
  }));
  const archiInternesOptions = persons
    .filter(p => !p.archived)
    .map(p => ({ label: `${p.firstName} ${p.lastName}`, value: p.uuid }));
  const showModeCalculCommission =
    status === LabellisationStatus.labellisee ||
    status === LabellisationStatus.activee ||
    status === LabellisationStatus.labellisationEnCours;

  const helperTextCommission = getHelperTextModeCommission({
    isArchitecte,
    isAllowedToUpdateCalculCommission,
    isExclusive,
  });

  return (
    <>
      <div className="flex flex-wrap gap-md">
        <div className="w-full sm-desktop:w-3/12 relative">
          <DatePickerField
            parse={parseDateInput}
            id="createdAt"
            name="createdAt"
            label="Date d'inscription*"
            disabled
          />
        </div>
        <div className="w-full sm-desktop:w-3/12 relative">
          <DropdownField
            id="status"
            name="status"
            label="Statut hemea*"
            options={labellisationStatusOptions}
          />
        </div>

        {showModeCalculCommission && (
          <div className="w-full sm-desktop:w-5/12 relative">
            <DropdownField
              id="modeCalculCommission"
              name="modeCalculCommission"
              label="Mode de calcul de commission"
              disabled={!!helperTextCommission}
              options={modeCalculCommissionOptions}
              helperText={helperTextCommission}
            />
          </div>
        )}
      </div>
      <div className="w-full my-sm relative">
        <h5 className="text-b1 font-bold">Exclusivité</h5>
        <div className="flex gap-sm">
          <CheckboxField id="isExclusive" name="isExclusive" label="Actif" />
          <DatePickerField
            parse={parseDateInput}
            disabled={!isExclusive}
            validate={isExclusive ? required : undefined}
            id="exclusiveStartAt"
            name="exclusiveStartAt"
            label="Date de début de l'exclusivité"
          />
        </div>
      </div>
      <div className="w-full my-sm relative">
        <h5 className="text-b1 font-bold">Charte professionnelle</h5>
        {charteStatus === CharteStatus.accepted ? (
          <div className="flex gap-sm items-center text-success-700">
            <CheckCircle className=" w-lg h-lg" />
            Le pro a validé la charte professionnelle
          </div>
        ) : (
          <CheckboxField
            id="charteStatus"
            name="charteStatus"
            label="Le pro ne peut pas valider la charte pro"
          />
        )}
      </div>
      <div className="flex flex-wrap pt-xl pb-sm gap-md">
        {isArchitecte && (
          <div className="w-full sm-desktop:w-3/12 relative">
            <DropdownField
              id="archiType"
              label="Type d'architecte"
              name="archiType"
              options={[
                { label: 'Interne', value: 'interne' },
                { label: 'Externe', value: 'externe' },
                { label: 'BET', value: 'bet' },
              ]}
            />
          </div>
        )}
        <div className="w-full sm-desktop:w-3/12 flex flex-col relative">
          <CheckboxField
            id="autonomeCommercialement"
            label="Autonome commercialement"
            name="autonomeCommercialement"
            className="block mb-md"
          />
          <CheckboxField
            id="acceptsBarnesProjects"
            label="Projets Barnes"
            name="acceptsBarnesProjects"
            className="block mb-md"
          />
          <CheckboxField
            id="acceptsHemeaProjects"
            label="Projets hemea"
            name="acceptsHemeaProjects"
            className="block mb-md"
          />
        </div>
        <div className="w-full sm-desktop:w-3/12 relative">
          <DropdownField
            id="companyType"
            label="Type d'entreprise"
            name="companyType"
            options={companyTypes}
          />
        </div>
        <div className="w-full sm-desktop:w-3/12 relative">
          <DropdownField
            id="recruiterUuid"
            name="recruiterUuid"
            label="Recruteur"
            disabled={!isAllowedToAssignRecruiterForCompany}
            options={cdplOptions}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-md">
        <div className="w-full sm-desktop:w-3/12 relative py-sm">
          <CheckboxGroupedField
            label="Pro profile options"
            name="proProfileFlags"
            options={proProfileFlags}
          />
        </div>
        <div className="w-full sm-desktop:w-3/12 relative py-sm">
          <NumberInputField
            id="nbOfEmployees"
            label="Nombre de salariés"
            name="nbOfEmployees"
            min={0}
            type="number"
          />
        </div>
        {archiType === 'interne' && (
          <>
            <div className="w-full sm-desktop:w-3/12 relative py-sm">
              <InputField
                id="archiInternePipedriveEmail"
                name="archiInternePipedriveEmail"
                label="Email Utilisateur Pipedrive"
              />
            </div>
            <div className="w-full sm-desktop:w-3/12 relative py-sm">
              <DropdownField
                id="archiInternePersonUUID"
                name="archiInternePersonUUID"
                label="Utilisateur hemea"
                options={archiInternesOptions}
              />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-wrap">
        <div className="w-full relative py-sm">
          <DropdownMultiField
            id="internal-work"
            label="Corps d'état réalisés en interne"
            name="travailEnInterne"
            options={travailEnInterne}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-md gap-md">
        <div className="w-full sm-desktop:w-4/12 relative">
          <DropdownMultiField label="Gammes" id="gammes" name="gammes" options={gammeOptions} />
        </div>
        <div className="w-full sm-desktop:w-4/12 relative">
          <DropdownMultiField
            options={departments}
            id="departements"
            name="departements"
            label="Départements"
          />
        </div>
      </div>
      {isArchitecte ? <FieldsArchitect /> : <FieldsPro chiffresAffaires={chiffresAffaires} />}
      <TextareaField
        className="mt-md"
        id="internalComment"
        name="internalComment"
        label="Commentaire en interne"
        rows={7}
      />
    </>
  );
};
