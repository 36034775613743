import React from 'react';

import classNames from 'classnames';

import {
  commonFormControlCheckmarkClasses,
  commonFormControlContainerClasses,
  FormControlProps,
  GenericFormControlContainer,
} from '../FormControl/commons';

export type Props<T> = FormControlProps<T> & { error?: string };

export function RadioButton<T = string>({
  className,
  label,
  checked,
  disabled,
  error,
  ...rest
}: Props<T>): React.ReactElement {
  return (
    <GenericFormControlContainer
      type="radio"
      label={label}
      className={className}
      checked={checked}
      disabled={disabled}
      {...rest}
    >
      <span
        className={classNames(
          'rounded-full inline-flex items-center justify-center relative shrink-0 size-xmd',
          { 'hover:bg-neutral-100': checked },
          commonFormControlContainerClasses(checked, disabled, !!error),
        )}
        aria-hidden
      >
        {checked ? (
          <span
            className={classNames(
              'rounded-full size-[10px]',
              commonFormControlCheckmarkClasses(checked, disabled),
            )}
          />
        ) : (
          <div className="size-[10px]" />
        )}
      </span>
    </GenericFormControlContainer>
  );
}
