import React, { useState } from 'react';

import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import logo from '@travauxlib/shared/src/components/DesignSystem/assets/Logo.svg';
import LogoutTool from '@travauxlib/shared/src/components/DesignSystem/assets/LogoutTool.svg?react';
import { PersonRole } from '@travauxlib/shared/src/types';

import { useLogout, useAuth } from 'api/useAuth';
import { useIsAllowed } from 'hooks/useIsAllowed';

import { navItems } from './utils/navItems';

export const DesktopSidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const logout = useLogout();
  const { adminUserProfile } = useAuth();

  const canManageFacturation = useIsAllowed('Facturation', 'manage');

  const permissions = {
    canManageFacturation,
    isCDPL: adminUserProfile.role === PersonRole.CDPL,
  };

  const filteredNavItems = navItems.filter(item => !item.show || item.show(permissions));

  return (
    <aside
      onMouseEnter={() => setIsOpen(true)}
      onTouchStart={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onTouchEnd={() => setIsOpen(false)}
      className="shrink-0 w-3xl h-dvh"
    >
      <div
        className={classNames(
          'fixed inset-y-0 transition-[width] w-3xl bg-white shadow-sm z-30 flex-col py-md px-xs',
          isOpen && '!w-[300px] duration-[500ms]',
        )}
      >
        <div className="mb-xxl">
          <div className="my-xs mx-xs flex overflow-hidden">
            <div className="overflow-hidden max-w-full flex justify-center shrink-0">
              <Link to="/">
                <img src={logo} className="h-xl w-[116px] shrink-0 bg-contain" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mb-xl flex flex-col gap-y-xxs">
          {filteredNavItems.map(({ Icon, to, label }) => (
            <NavLink
              onClick={() => setIsOpen(false)}
              key={to}
              to={to}
              className={({ isActive }) =>
                classNames(
                  '!no-underline hover:bg-neutral-100 rounded-sm text-neutral-800 text-b1 font-bold p-sm flex no-wrap overflow-hidden',
                  isActive && '!text-primary bg-neutral-100',
                )
              }
            >
              <Icon className="w-lg h-lg mr-xxs shrink-0" />
              <span className={classNames('hidden whitespace-nowrap', isOpen && '!block')}>
                {label}
              </span>
            </NavLink>
          ))}
        </div>
        <div className="flex flex-col">
          <button
            type="button"
            onClick={logout}
            className="hover:bg-neutral-100 rounded-sm text-neutral-800 text-b1 font-bold p-sm flex no-wrap overflow-hidden bg-transparent"
          >
            <LogoutTool className="w-lg h-lg mr-xxs shrink-0" />
            <span className={classNames('hidden whitespace-nowrap', isOpen && '!block')}>
              Déconnexion
            </span>
          </button>
        </div>
      </div>
    </aside>
  );
};
