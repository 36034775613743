import React, { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { AlertVariant, BrandVariant } from '@travauxlib/shared/src/types/designSystem';

import { WithTooltip } from '../WithTooltip';

type ProgressBarSize = 'sm' | 'md' | 'lg';

export type ProgressBarVariant =
  | BrandVariant
  | Extract<AlertVariant, 'success' | 'info' | 'error'>
  | 'bronze'
  | 'neutral'
  | 'successLight'
  | 'neutralDark'
  | 'warning';
const PROGRESS_BAR_CONTENT_MIN_OUTER_MARGIN = 25;
const PROGRESS_BAR_INDEX_LAST_VALUE = 2;
const PROGRESS_BAR_INDEX_MIDDLE_VALUE = 1;

const labelContainerClassNamesBySize: { [key in ProgressBarSize]: string } = {
  sm: 'text-sm mb-xxs',
  md: 'text-b2 mb-xxs',
  lg: 'text-b1 mb-xs',
};

const progressBarClassNamesBySize: { [key in ProgressBarSize]: string } = {
  sm: 'h-xxs',
  md: 'h-xs',
  lg: 'h-md',
};

const progressBarClassNamesByVariant: {
  [key in ProgressBarVariant]: {
    bg: string;
    fill: string;
  };
} = {
  primary: { bg: 'bg-primary-400', fill: 'fill-primary-400' },
  info: { bg: 'bg-info-400', fill: 'fill-info-400' },
  success: { bg: 'bg-success-400', fill: 'fill-success-400' },
  successLight: { bg: 'bg-success-200', fill: 'fill-success-200' },
  error: { bg: 'bg-error-400', fill: 'fill-error-400' },
  bronze: { bg: 'bg-primary-200', fill: 'fill-primary-200' },
  neutral: { bg: 'bg-neutral-400', fill: 'fill-neutral-400' },
  neutralDark: { bg: 'bg-neutral-200', fill: 'fill-neutral-200' },
  warning: { bg: 'bg-warning-300', fill: 'fill-warning-300' },
};

const calculateWidthProgressBars = (
  index: number,
  progressBarWidth: number,
  hasManyValues: boolean,
): string => {
  if (progressBarWidth <= 0) {
    // Temporary fix to avoid displaying dot on single zero loading bars
    return hasManyValues ? '18px' : '0px';
  }

  if (index === PROGRESS_BAR_INDEX_MIDDLE_VALUE || index === PROGRESS_BAR_INDEX_LAST_VALUE) {
    return `calc(${progressBarWidth}% + 11px)`;
  }

  return `${progressBarWidth}%`;
};

export type ProgressBarValue = {
  value: number;
  text?: string | React.ReactElement;
  variant?: ProgressBarVariant;
  showTooltip?: boolean;
};

const defaultBgColors = ['primary', 'bronze', 'neutral'] as ProgressBarVariant[];
const zIndexForPosition = ['10', '5', '1'];
const getValueAsPercentage = (value: number, max: number): number =>
  Math.round((value / max) * 100);

export const createProgressBarValues = (
  values: number | ProgressBarValue[],
  max: number,
): ProgressBarValue[] => {
  if (typeof values === 'number') {
    return [
      {
        value: values,
        text: `${getValueAsPercentage(values, max)} %`,
      },
    ];
  }

  if (values.length > 3) {
    throw new Error('Only 3 values are supported for now');
  }

  return values.map(({ value, text, variant, ...rest }, index) => ({
    value,
    text: text ?? `${getValueAsPercentage(value, max)} %`,
    variant: variant ?? defaultBgColors[index],
    ...rest,
  }));
};

type Props = {
  value: number | ProgressBarValue[];
  label?: string | React.ReactElement;
  formattedValue?: string | React.ReactElement;
  size?: ProgressBarSize;
  variant?: ProgressBarVariant;
  min?: number;
  max?: number;
  className?: string;
  isFormatedValueBottom?: boolean;
  onMouseMove?: (event: React.MouseEvent) => void;
};

export const ProgressBar: React.FC<Props> = ({
  label,
  value,
  size = 'md',
  isFormatedValueBottom,
  variant: variantGlobal = 'primary',
  min = 0,
  max = 100,
  formattedValue,
  className,
  onMouseMove,
}) => {
  const values = useMemo(() => createProgressBarValues(value, max), [value, max]);

  const progressBarRef = useRef<HTMLDivElement>(null);
  const [displayProgressBarPercentValue, setDisplayProgressBarPercentValue] = useState<boolean[]>(
    [],
  );
  const [windowSize, setWindowSize] = React.useState(0);
  const hideAllInfos = !label && !formattedValue;

  useEffect(() => {
    window.addEventListener('resize', (e: UIEvent) => {
      const typedTarget = e.target as Window;
      if (typedTarget?.innerWidth) {
        setWindowSize(typedTarget.innerWidth);
      }
    });
  }, []);

  // Hide percent value when the progress bar is too small
  useEffect(() => {
    if (size !== 'lg') {
      return setDisplayProgressBarPercentValue(values.map(() => false));
    }

    const arrayChildsProgressBar = Array.from(progressBarRef.current?.childNodes || []);
    const checkChildProgressWidth = arrayChildsProgressBar.map((item: HTMLDivElement) => {
      const progressBarChildWidth = item.getBoundingClientRect().width || 0;
      const progressBarChildContentWidth =
        (item.firstChild as HTMLSpanElement)?.getBoundingClientRect().width || 0;

      return (
        progressBarChildWidth > progressBarChildContentWidth + PROGRESS_BAR_CONTENT_MIN_OUTER_MARGIN
      );
    });

    setDisplayProgressBarPercentValue(checkChildProgressWidth);
  }, [windowSize, size, value]);

  return (
    <div className={className}>
      {!hideAllInfos && (
        <div className={classNames('flex text-neutral-800', labelContainerClassNamesBySize[size])}>
          {/* TODO regarde dans le DS si faut l'appliquer a tous */}
          {label && <div className="truncate font-bold">{label}</div>}
          {!isFormatedValueBottom && formattedValue && (
            <div className="font-bold ml-auto shrink-0 max-w-full truncate">{formattedValue}</div>
          )}
        </div>
      )}
      <div
        ref={progressBarRef}
        className={classNames('bg-neutral-200 rounded-xs flex items-center', {
          'text-neutral-0': values.length === 3 && values[2].variant !== 'neutral',
        })}
      >
        {values.map(({ value, text, variant, showTooltip }, index) => {
          const valueAsPercentage = getValueAsPercentage(value, max);
          const progressBarWidth = Math.min(valueAsPercentage, 100);

          const progressBarContent = (
            <div
              key={`${value}-${index}`}
              onMouseMove={onMouseMove}
              role="progressbar"
              aria-valuenow={value}
              aria-valuemin={min}
              aria-valuemax={max}
              //eslint-disable-next-line
              style={{
                width: calculateWidthProgressBars(index, progressBarWidth, values.length > 1),
                zIndex: zIndexForPosition[index],
              }}
              className={classNames(
                'rounded-xs relative',
                'text-xs text-neutral-0',
                'text-center',
                progressBarClassNamesBySize[size],
                progressBarClassNamesByVariant[variant ?? variantGlobal].bg,
                {
                  '!text-inherit': index === PROGRESS_BAR_INDEX_LAST_VALUE,
                  '-m-[11px]': index === PROGRESS_BAR_INDEX_MIDDLE_VALUE,
                },
              )}
            >
              <span
                className={classNames({
                  'invisible absolute': !displayProgressBarPercentValue[index],
                })}
              >
                {text}
              </span>
            </div>
          );

          return showTooltip && !displayProgressBarPercentValue[index] ? (
            <WithTooltip
              bgColor={progressBarClassNamesByVariant[variant ?? variantGlobal].bg}
              fillColor={progressBarClassNamesByVariant[variant ?? variantGlobal].fill}
              key={`tooltip-${value}-${index}`}
              trigger={progressBarContent}
              position="left"
              width="auto"
              className="!py-0"
            >
              <span>{text}</span>
            </WithTooltip>
          ) : (
            progressBarContent
          );
        })}
      </div>
      {isFormatedValueBottom && !hideAllInfos && (
        <div
          className={classNames(
            'flex text-neutral-800',
            labelContainerClassNamesBySize[size],
            '!mb-0',
          )}
        >
          {formattedValue && (
            <div className="font-bold ml-auto shrink-0 max-w-full truncate ">{formattedValue}</div>
          )}
        </div>
      )}
    </div>
  );
};
